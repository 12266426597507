<template>
  <footer class="footer mt-auto py-3">
    <div class="d-flex align-items-center justify-content-between">
      <span>Copyright, All Rights Reserved</span>
      <span class="text-right">Provided by PCASA Operations Pty Ltd</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style></style>
