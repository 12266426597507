<template>
  <div>
    <div class="header mt-3 mb-4 d-flex align-items-center">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="logo" />
        </router-link>
      </div>
      <portal-target name="header-right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style scoped lang="scss">
.logo {
  img {
    width: 100%;
    max-width: 450px;
  }
}
</style>
