import Vue from 'vue';
import VueRouter from 'vue-router';
import pages from '@/shared/pages';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: pages['revive-apple'].path,
  },
  {
    path: pages['revive-apple'].path,
    redirect: pages['revive-apple'].children.selectDevice.path,
  },
  {
    path: pages['revive-apple'].path,
    name: pages['revive-apple'].name,
    component: () => import('@/views/revive/Revive.vue'),
    props: true,
    children: [
      {
        name: pages['revive-apple'].children.selectDevice.name,
        path: pages['revive-apple'].children.selectDevice.path,
        component: () => import('@/views/revive/steps/SelectDevice.vue'),
        props: true,
      },
      {
        name: pages['revive-apple'].children.removeDevice.name,
        path: pages['revive-apple'].children.removeDevice.path,
        component: () => import('@/views/revive/steps/RemoveDevice.vue'),
        props: true,
      },
      {
        name: pages['revive-apple'].children.packageDevice.name,
        path: pages['revive-apple'].children.packageDevice.path,
        component: () => import('@/views/revive/steps/PackageDevice.vue'),
        props: true,
      },
      {
        name: pages['revive-apple'].children.sendDevice.name,
        path: pages['revive-apple'].children.sendDevice.path,
        component: () => import('@/views/revive/steps/SendDevice.vue'),
        props: true,
      },
    ],
  },
  {
    path: pages['revive-dyson'].path,
    redirect: pages['revive-dyson'].children.selectDevice.path,
  },
  {
    path: pages['revive-dyson'].path,
    name: pages['revive-dyson'].name,
    component: () => import('@/views/revive/Revive.vue'),
    props: true,
    children: [
      {
        name: pages['revive-dyson'].children.selectDevice.name,
        path: pages['revive-dyson'].children.selectDevice.path,
        component: () => import('@/views/revive/steps/SelectDevice.vue'),
        props: true,
      },
      {
        name: pages['revive-dyson'].children.packageOption.name,
        path: pages['revive-dyson'].children.packageOption.path,
        component: () => import('@/views/revive/steps/PackageOption.vue'),
        props: true,
      },
      {
        name: pages['revive-dyson'].children.packageInstructions.name,
        path: pages['revive-dyson'].children.packageInstructions.path,
        component: () => import('@/views/revive/steps/PackageDevice.vue'),
        props: true,
      },
      {
        name: pages['revive-dyson'].children.sendDevice.name,
        path: pages['revive-dyson'].children.sendDevice.path,
        component: () => import('@/views/revive/steps/SendDevice.vue'),
        props: true,
      },
    ],
  },
  { path: '*', component: () => import('@/views/PageNotFound.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
