export default {
  "revive-apple": {
    name: 'revive',
    label: 'Revive',
    path: '/revive',
    children: {
      selectDevice: {
        name: 'select',
        label: 'Select Product',
        path: '/revive/select-device',
        step: 1,
      },
      removeDevice: {
        name: 'remove',
        label: 'Remove Product',
        path: '/revive/:device/remove-device',
        route: 'remove-device',
        step: 2,
      },
      packageDevice: {
        name: 'package',
        label: 'Package Product',
        path: '/revive/:device/package-device',
        route: 'package-device',
        step: 3,
      },
      sendDevice: {
        name: 'send',
        label: 'Send Product',
        path: '/revive/:device/send-device',
        route: 'send-device',
        step: 4,
      },
    }
  },
  "revive-dyson": {
    name: 'revive dyson',
    label: 'Revive Dyson',
    path: '/revive-dyson',
    children: {
      selectDevice: {
        name: 'select-dyson',
        label: 'Select Product',
        path: '/revive-dyson/select-device',
        step: 1,
      },
      packageOption: {
        name: 'package-option-dyson',
        label: 'Package Options',
        path: '/revive-dyson/:device/package-option',
        route: 'package-device',
        selected: {
          "package-option": {
            yes: {
              "Air Wrap": ["productInstruction3"],
              "Hair Dryer": "productInstruction3",
              "Stick Vacs": "productInstruction3",
            },
            no: {
              "Air Wrap": "productInstruction1",
              "Hair Dryer": "productInstruction1",
              "Stick Vacs": "productInstruction2",
            }
          },
          "Air Purifier": "productInstruction4",
        },
        step:2,
        devices: ['stick-vacuums','hair-dryer','air-wrap']
      },
      packageInstructions: {
        name: 'package-dyson',
        label: 'Package Product',
        path: '/revive-dyson/:device/package-instructions',
        step: 3,
        devices: ['barrel-vacuums','upright-vacuums','air-purifiers']
      },
      sendDevice: {
        name: 'send-dyson',
        label: 'Send Product',
        route: 'send-device',
        path: '/revive-dyson/:device/send-device',
        step: 4,
      }
    }
  },
};
