<template>
  <div class="d-flex flex-column min-vh-100 container bg-white px-4">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from '@/components';

export default {
  components: { Header, Footer },
};
</script>
